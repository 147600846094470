/* eslint-disable object-curly-spacing */
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

const GbiBridged = (): React.ReactElement => {
  return (
    <section className='masthead'>
      <StaticImage style={{ gridArea: '1/1' }} src='../images/karriere-bei-triceonic-img2.jpg' alt='Leistungsstarke Strategieumsetzung' placeholder='none' />
    </section>
  );
};

export default GbiBridged;
