import * as React from 'react';
import '../styles/grid.css';
import '../styles/styles.css';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import BgImage from '../components/HeaderCareer';

export default function career (): React.ReactElement {

  return (
    <Layout>
      <div id='career'>
        <div className='imageWrapper clipPathBottom'>
          <BgImage />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1>Karriere bei Triceonic</h1>
              <p>Du suchst nach einer Stelle die vielseitig ist und Freiraum bietet? Wir haben die passende Stelle für dich. Starte mit uns durch und werde Teil unseres Teams!<br />
                Wir denken Strategieumsetzung neu. Mit unserem Software-Produkt ermöglichen wir erstmals, Beziehungen zwischen strategischen Zielen und operativen Aufgaben vollständig abzubilden und aktuell zu halten. Wir lieben komplexe Herausforderungen und arbeiten mit Triceonic konsequent daran, ein hocheffizientes und gleichzeitig motivierendes Arbeitsumfeld für unsere Kunden und deren Mitarbeiter zu schaffen. Wir stehen für Qualität und eigenverantwortliches Handeln und freuen uns auf Verstärkung bei dieser Mission.
              </p>
            </div>
          </div>
        </div>
        <div className='eyeCatcher'>
          <div className='container'>
            <div className='row'>
              <div className='col-9'>
                <p>Zur Zeit suchen wir nicht aktiv, aber vielleicht wissen wir einfach noch nicht, dass wir genau dich brauchen, also bewirb dich einfach gleich jetzt!</p>
              </div>
              <div className='col-3'>
                <a href='tel:+437203712391750'>
                  <button className='lightButton'>
                    <FontAwesomeIcon className='fontAwesomeIcon' icon={faPhone} />
                    Anrufen
                  </button>
                </a>
                <a href='mailto:career@triceonic.com'>
                  <button className='lightButton'>
                    <FontAwesomeIcon className='fontAwesomeIcon' icon={faEnvelope} />
                    Schreiben
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  );
}
